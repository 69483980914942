$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-button").click(function() {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
    })

    $(".js-nav-mobile-parent").click(function() {
        $(this).siblings(".js-nav-mobile-content").slideToggle().parent().toggleClass("open");
    })

    // trigger active menu-parents
	$('.js-nav-mobile li.active > .js-nav-mobile-parent').trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav layout
    // *
    // *

    $(".js-nav-layout-parent > svg").click(function() {
        $(this).parent(".js-nav-layout-parent").siblings(".js-nav-layout-content").slideToggle().parent().toggleClass("open");
    })

    $(".level-2.active > .js-nav-layout-parent > svg").trigger("click");
    $(".level-2.active").parents(".js-nav-layout-content").show();

    $(".level-3.active").parents(".js-nav-layout-content").siblings(".js-nav-layout-parent").find("svg").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * quote slider
	// *
	// *
	var $quoteSlider = $(".js-quote-slider");

	if ($quoteSlider.length){
		$quoteSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: false,
				// autoplay: true,
                // autoplaySpeed: 4000,
				speed: 1000,
                slidesToShow: 3,
                responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * logoCarousel
	// *
	// *
	var $logoCarousel = $(".js-logo-carousel");

	if ($logoCarousel.length){
		$logoCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
                slidesToShow: 6,
                slidesToScroll: 3,
				appendDots: $(".js-logo-carousel-nav-dots"),
				prevArrow: $(".js-logo-carousel-nav-prev"),
				nextArrow: $(".js-logo-carousel-nav-next"),
                responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 3
                  }
                }
              ]
			});
		});
	}    
    
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * productSlider
	// *
	// *
	var $productSlider = $(".js-product-slider");

	if ($productSlider.length){
		$productSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: false,
				arrows: true,
				autoplay: false,
				speed: 1000,
				prevArrow: $(this).parent().find(".js-slider-nav-prev"),
				nextArrow: $(this).parent().find(".js-slider-nav-next"),
			});
		});
	}







    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popUp
    // *
    // *

    // close popUp
    $(".js-popup-close").click(function(event){
        event.preventDefault();
        window.location.hash = '#';
        $(".js-popup").removeClass("active");
        $("html, body").removeClass("freeze");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length){
        $slider.each(function(){
            var $sliderImages = $(this).find(".js-slider-images"),
                $sliderContent = $(this).find(".js-slider-content");

            $sliderImages.slick({
                fade: true,
                dots: false,
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                asNavFor: $sliderContent
            });

            $sliderContent.slick({
                fade: false,
                dots: true,
                appendDots: $(this).find(".js-slider-nav-dots"),
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                asNavFor: $sliderImages
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * step input
    // *
    // *
    $(document).on("change", ".js-step-input", function() {
        var currentValue = $(this).val();
        var step = $(this).data("step");

        if (currentValue % step != 0) {
            var newValue = Number(currentValue) + (Number(step) - (Number(currentValue) % Number(step)));
            $(this).val(newValue).trigger("change");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tools
    // *
    // *
    $(".js-tool-header").on("click", function() {
        // open menu
        $(this).siblings(".js-tool-inner").toggleClass("open");

        // close other menu
        $(this).parents(".js-tool").siblings().find(".js-tool-inner").removeClass("open");

        // show overlay (close button) when list is shown
        if ( $(".js-tool-inner.open").length ) {
            $(".js-tool-overlay").show();
        } else {
            $(".js-tool-overlay").hide();
        };
    });

    // close menu with overlay
    $(".js-tool-overlay").click(function(){
        $(this).hide();
        $(".js-tool-inner").removeClass("open");
    });

    // toggle button active class
    $(".js-tool-button").click(function(){
        $(this).toggleClass("active");
        $(this).siblings().removeClass("active");
    });

    // dropdown slide down and siblings up
    $(".js-tool-dropdown-button").click(function(){
        $(this).toggleClass("active").siblings().slideToggle().parents(".js-tool-dropdown").siblings().find(".js-tool-dropdown-button").removeClass("active").siblings(".js-tool-dropdown-list").slideUp();
    });

    // checkbox toggle
    $(".js-tool-checkbox").click(function(){
        $(this).toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * addToCart
    // *
    // *
    var $addToCartForm = $(".js-addtocart-form");

    $addToCartForm.submit(function(e) {
        e.preventDefault();

        $.ajax({
            url : "/assets/components/site/connector.php?action=addtocart",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if (data.status == 'success') {

                // set content
                $(".js-asidecart-summary").html(data.html_summary);

                // show aside cart
                showAsideCart();

            }else if (data.status == 'error') {
                // handle Error
                console.log(data);
            }
        });

    });

    // show aside Cart and load items
    function showAsideCart() {
        $(".js-asidecart").addClass("active");
        $(".js-asidecart-overlay").addClass("active");
        $("body").addClass("freeze");
    }

    // close aside cart
    $(".js-asidecart-close").click(function(){
        $(".js-asidecart").removeClass("active");
        $(".js-asidecart-overlay").removeClass("active");
        $("body").removeClass("freeze");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cart & aside cart
    // *
    // *

    // remove item from asidecart
    $(".js-cart").on("click", ".js-cart-remove", function(){
        $(this).parents(".js-cart-item").find(".js-cart-quantity").val(0).trigger("change");
        $(this).parents(".js-cart-item").remove();
    });

    // change quantity
    $(".js-cart").on("change", ".js-cart-quantity", function(){
        var $item = $(this).parents(".js-cart-item");

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatecartquantity",
            type: 'GET',
            data: {"id": $(this).data("product-id"), "quantity": $(this).val()}
        }).done(function(data){
            if (data.status == 'success') {

                // set content
                $(".js-asidecart-summary").html(data.html_summary);

                // redirect to big cart if cart is empty
                if (data.order.total_items <= 0) {
                    var urlToCart = $(".js-gotocart-button").attr("href");
                    window.location.href = urlToCart;
                }

                // change total
                $item.find(".js-cart-item-total").html(data.item.total);
                $item.find(".js-cart-item-totalfee").html(data.item.total_fee);
                $item.find(".js-cart-item-totalextax").html(data.item.total_ex_tax);
                $(".js-cart-summary-total").html(data.order.total);
                $(".js-cart-summary-totalextax").html(data.order.total_ex_tax);
                $(".js-cart-summary-totaltax").html(data.order.total_tax);
                $(".js-cart-summary-totalprice").html(data.order.total_price);
                $(".js-cart-summary-totalfee").html(data.order.total_fee);

            }else if (data.status == 'error') {
                // handle Error
                console.log(data);
            }
        });
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * form toggle
    // *
    // *
    $(".js-form-toggle").click(function(){
        $(this).toggleClass("active");
        $(".js-form-toggle-form").toggleClass("active").find("input").val("");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * reference
    // *
    // *
    $(".js-reference-button").click(function(){
        $(this).toggleClass("active").siblings(".js-reference").find(".js-reference-overlay").toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Add Address
    // *
    // *
    var $addAddressForm = $(".js-form-addaddress");

    $addAddressForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-input").removeClass("form__input--error");

        $.ajax({
            url : "/assets/components/site/connector.php?action=addaddress",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if (data.status == 'success') {

                // set address
                $(".js-address-" + data.address.type).html(data.address.formatted);
                $(".js-" + data.address.type + "-id").val(data.address.id);

                // hide popup
                window.location.hash = '#';

                // empty form
                $addAddressForm.find(".js-input").val('');


            }else if (data.status == 'error') {
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-input-" + index).addClass("form__input--error");
                });
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Select Address
    // *
    // *
    var $selectAddressForm = $(".js-form-selectaddress");

    $selectAddressForm.submit(function(e) {
        e.preventDefault();

        $.ajax({
            url : "/assets/components/site/connector.php?action=selectaddress",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if (data.status == 'success') {

                // set address
                $(".js-address-" + data.address.type).html(data.address.formatted);
                $(".js-" + data.address.type + "-id").val(data.address.id);

                // hide popup
                window.location.hash = '#';

                // empty form
                $selectAddressForm.find(".js-input").val('');


            }else if (data.status == 'error') {
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-input-" + index).addClass("form__input--error");
                });
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * update Address
    // *
    // *
    var $updateAddressForm = $(".js-form-updateaddress");

    $updateAddressForm.submit(function(e) {
        e.preventDefault();

        $.ajax({
            url : "/assets/components/site/connector.php?action=updateaddress",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if (data.status == 'success') {

                // change address
                $(".js-address-" + data.address.id).html(data.address.formatted);

                // hide popup
                window.location.hash = '#';

            }else if (data.status == 'error') {
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-input-" + index).addClass("form__input--error");
                });
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * update Personal Account Data
    // *
    // *
    var $updateProfileForm = $(".js-form-updateprofile");

    $updateProfileForm.submit(function(e) {
        e.preventDefault();
        console.log('test');
        $.ajax({
            url : "/assets/components/site/connector.php?action=updateprofile",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            console.log(data);
            if (data.status == 'success') {

                // hide popup
                window.location.hash = '#';

                // refresh site
                window.location.reload();

            }else if (data.status == 'error') {
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-input-" + index).addClass("form__input--error");
                });
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add to List
    // *
    // *
    $(".js-addtolist-button").click(function(e){
        e.preventDefault();

        var $listButton = $(this);

        $listButton.addClass("setactive");

        $.ajax({
            url : "/assets/components/site/connector.php?action=addtolist",
            type: 'GET',
            data: {"id": $(this).data("product-id")}
        }).done(function(data){
            if (data.status == 'success') {

                // set address
                $listButton.addClass("active");

            }else if (data.status == 'error') {
                // handle Error
                console.log(data);
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add to List - Remove
    // *
    // *
    $(".js-addtolist-remove").click(function(e){
        e.preventDefault();

        var $item = $(this).parents(".js-fav-item");

        $.ajax({
            url : "/assets/components/site/connector.php?action=removefromlist",
            type: 'GET',
            data: {"id": $(this).data("product-id")}
        }).done(function(data){
            if (data.status == 'success') {

                // remove item
                $item.remove();

            }else if (data.status == 'error') {
                // handle Error
                console.log(data);
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * reorder
    // *
    // *
    $(".js-reorder-button").click(function(e){
        e.preventDefault();

        var $item = $(this).parents(".js-fav-item");

        $.ajax({
            url : "/assets/components/site/connector.php?action=reorder",
            type: 'GET',
            data: {"id": $(this).data("order")}
        }).done(function(data){
            if (data.status == 'success') {

                // redirect to cart
                var urlToCart = $(".js-gotocart-button").attr("href");
                window.location.href = urlToCart;

            }else if (data.status == 'error') {
                // handle Error
                console.log(data);
            }
        });
    });


});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * modal window
// *
// *
$(window).on('hashchange load', function () {
    var hash = window.location.hash.split('#')[1],
        $modal = $('[data-popup="' + hash + '"]');

    $(".js-popup").removeClass("active");
    $("html, body").removeClass("freeze");

    if ($modal.length) {
        $modal.addClass("active");
        $("html, body").addClass("freeze");
    }
});
